<template>
  <div class="career">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_career.add_career")
    }}</b-button>
    <b-modal id="modal-xl" v-model="modalShow" size="xl" hide-footer>
      <form @submit.prevent="addCareer">
        <div class="start_date">
          <label for="example-datepicker">{{
            $t("add_career.start_date")
          }}</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="form.startDate"
            class="mb-2"
          ></b-form-datepicker>
        </div>
        <div class="end_date">
          <label for="example-datepicker">{{
            $t("add_career.end_date")
          }}</label>
          <b-form-datepicker
            id="example-datepicker2"
            v-model="form.endDate"
            class="mb-2"
          ></b-form-datepicker>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("admin.description") }} (KA)</label>
          <b-form-input v-model="form.descriptionKA"></b-form-input>
          <label for="">{{ $t("admin.description") }} (EN)</label>
          <b-form-input v-model="form.descriptionEN"></b-form-input>
        </div>
        <label for="">{{ $t("admin_sidebar.career_category") }}</label>
        <select name="" id="" v-model="form.category" required>
          <option value="choose category" disabled>
            {{ $t("add_category.choose_category") }}
          </option>
          <option
            :value="category._id"
            v-for="category in allCategory.category"
            :key="category._id"
          >
            {{ category.titleKA }}
          </option>
        </select>
        <br />
        <label for="">{{ $t("add_career.location") }}</label>
        <select name="" id="" v-model="form.location" required>
          <option value="choose location" disabled>
            {{ $t("add_category.choose_location") }}
          </option>
          <option
            :value="category._id"
            v-for="category in allCategory.location"
            :key="category._id"
          >
            {{ category.titleKA }}
          </option>
        </select>
        <div class="text_editor">
          <label for=""> {{ $t("admin.keywords") }}</label>
          <i class="fal fa-plus-square" @click="add_component()"></i>
          <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
            <input
              class="input add_component"
              type="text"
              v-model="comp[i]"
              @change="get_value($event)"
            />
            <i class="fal fa-times-square delete_comp" @click="delete_comp"></i>
          </div>
        </div>
        <div>
          <label for="">{{ $t("admin.activate_on_front") }}</label>
        </div>
        <input class="checkbox" type="checkbox" v-model="form.show" />
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  components: {
    VueEditor,
    spinner,
  },
  name: "login",
  data() {
    return {
      modalShow: false,
      form: {
        startDate: "",
        endDate: "",
        show: false,
        keywords: [],
        descriptionKA: "",
        descriptionEN: "",
        category: null,
        location: null,
      },
      allCategory: [],
      components: [{ comp: "comp" }],
      isLoading: false,
    };
  },
  props: {
    getCareer: { type: Function },
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      axios.get(`${env.host}/get/all/career/categories/admin`).then((res) => {
        this.allCategory = res.data.item;
      });
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    get_value(e) {
      this.form.keywords.push(e.target.value);
    },
    addCareer() {
      this.isLoading = true;
      this.form.startDate = new Date(this.form.startDate).toString();
      this.form.endDate = new Date(this.form.endDate).toString();
      axios
        .post(`${env.host}/add/career`, this.form)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          this.emptyValues();
          setTimeout(() => {
            this.getCareer();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    emptyValues() {
      this.form.startDate = "";
      this.form.endDate = "";
      this.form.show = false;
      this.form.keywords = [];
      this.form.descriptionKA = "";
      this.form.descriptionEN = "";
      this.form.category = null;
      this.form.location = null;
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}

button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
select,
.input {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-left: 10px;
  height: 40px;
}
.career {
  margin-bottom: 10px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
i {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}
.add_component {
  margin-bottom: 20px;
}
</style>
