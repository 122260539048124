<template>
  <div class="career_management">
    <AddCareer :getCareer="getCareer" />
    <div class="main_div2">
      <div
        class="box"
        style="height: 50px; color: white; background: #7e5493; padding: 10px"
        v-for="item in header"
        :key="item.id"
      >
        <label for="">{{ item }} </label>
      </div>
    </div>
    <Container
      behaviour="contain"
      orientation="vertical"
      @drop="onDrop"
      :remove-on-drop-out="true"
      drag-class="dragging"
    >
      <div v-if="!isLoading" class="testingScroll">
        <Draggable v-for="(career, index) in allCareer" :key="index">
          <div class="main_div">
            <div class="box show_on_front">
              <i v-if="career.show == true" class="fal fa-check-circle"></i>
              <i v-if="career.show == false" class="fal fa-ban"></i>
            </div>
            <div class="box">
              <span v-if="lang == 'ka'">{{ career.startDateKA }}</span>
              <span v-else>{{ career.startDateEN }}</span>
            </div>
            <div class="box">
              <span v-if="lang == 'ka'">{{ career.endDateKA }}</span>
              <span v-else>{{ career.endDateEN }}</span>
            </div>
            <div class="box">
              <div v-if="career.category">
                <span v-if="lang == 'ka'">{{ career.category.titleKA }}</span>
                <span v-else>{{ career.category.titleEN }}</span>
              </div>
            </div>
            <div class="box">
              <div class="short_text" v-if="career.category">
                <div style="word-wrap: break-word">
                  <span v-if="lang == 'ka'">{{ career.location.titleKA }}</span>
                  <span v-else>{{ career.location.titleEN }}</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div
                v-if="lang == 'ka'"
                :ref="'descriptionKA' + index"
                class="short_text"
                style="padding-left: 20px"
              ></div>
              <div
                v-else
                :ref="'descriptionEN' + index"
                class="short_text"
                style="padding-left: 20px"
              ></div>
            </div>
            <div class="box" style="display: block">
              <div v-for="item in career.keywords" :key="item.id">
                {{ item }}
              </div>
            </div>
            <div class="box">
              <b-button
                @click="
                  (modalShow = !modalShow),
                    editCareer(
                      career._id,
                      career.startDate,
                      career.endDate,
                      career.show,
                      career.keywords,
                      career.category,
                      career.location,
                      career.descriptionKA,
                      career.descriptionEN
                    )
                "
              >
                <i class="fal fa-pen"></i>
              </b-button>
            </div>
            <div class="box">
              <ConfirmDelete @clicktodelete="deleteCareer(career._id)" />
            </div>
          </div>
        </Draggable>
      </div>
      <div v-else>
        <b-skeleton-table
          :rows="3"
          :columns="9"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
      </div>
    </Container>

    <b-modal
      class="BMODAL"
      v-model="modalShow"
      id="modal-xl-2"
      size="xl"
      hide-footer
      v-if="edit"
    >
      <div class="start_date">
        <label class="mtavruliBOLD" for="example-datepicker">{{
          $t("add_career.start_date")
        }}</label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="update.startDate"
          class="mb-2"
        ></b-form-datepicker>
      </div>
      <div class="end_date">
        <label class="mtavruliBOLD" for="example-datepicker">{{
          $t("add_career.end_date")
        }}</label>
        <b-form-datepicker
          id="example-datepicker2"
          v-model="update.endDate"
          class="mb-2"
        ></b-form-datepicker>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin.description") }} (KA)</label>
        <b-form-input v-model="update.descriptionKA"></b-form-input>
        <label for="">{{ $t("admin.description") }} (EN)</label>
        <b-form-input v-model="update.descriptionEN"></b-form-input>
      </div>
      <div class="text_editor" v-if="update.category !== null">
        <label for=""> {{ $t("add_category.selected_category") }}</label>
        <input class="input" v-model="update.category.titleKA" readonly />
      </div>
      <div>
        <label class="mtavruliBOLD" for="">{{
          $t("admin_sidebar.career_category")
        }}</label>
        <select name="" id="" v-model="update.category">
          <option value="choose category" disabled>
            {{ $t("add_category.choose_category") }}
          </option>
          <option
            :value="category.id"
            v-for="category in allCategory.category"
            :key="category.id"
          >
            <span v-if="lang=='ka'">{{ category.titleKA }}</span>
            <span v-else>{{ category.titleEN }}</span>
          </option>
        </select>
      </div>
      <br />
      <label class="mtavruliBOLD" for="">{{ $t("add_career.location") }}</label>
      <select name="" id="" v-model="update.location">
        <option value="choose location" disabled>
          {{ $t("add_category.choose_location") }}
        </option>
        <option
          :value="category._id"
          v-for="category in allCategory.location"
          :key="category._id"
        >
          <span v-if="lang=='ka'">{{ category.titleKA }}</span>
          <span v-else>{{ category.titleEN }}</span>
        </option>
      </select>
      <div class="text_editor" v-if="update.keywords.length !== 0">
        <label class="mtavruliBOLD" for="">
          {{ $t("admin.choosed_keywords") }}</label
        >
        <div
          v-for="(item, index) in update.keywords"
          :key="index"
          class="d-flex"
        >
          <div class="choosed_keywords">
            {{ item }}
          </div>
          <i
            class="fal fa-times-square"
            style="cursor: pointer; margin-left: 20px; font-size: 20px"
            @click="delete_keywords(index)"
          ></i>
        </div>
      </div>
      <div class="text_editor add_comp">
        <label for=""> {{ $t("admin.keywords") }}</label>
        <i class="fal fa-plus-square" @click="add_component()"></i>
        <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
          <input
            class="input add_component"
            type="text"
            v-model="comp[i]"
            @change="get_value($event)"
          />
          <i class="fal fa-times-square delete_comp" @click="delete_comp"></i>
        </div>
      </div>
      <div>
        <label class="mtavruliBOLD" for="">{{
          $t("admin.activate_on_front")
        }}</label>
      </div>
      <input class="checkbox" type="checkbox" v-model="update.show" />
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateCareer()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import { Container, Draggable } from "vue-smooth-dnd";
import AddCareer from "../AddComponents/AddCareer.vue";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  const result = [...arr];
  let itemToAdd = payload;
  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }
  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

export default {
  name: "getTeam",
  components: {
    AddCareer,
    VueEditor,
    Container,
    Draggable,
    spinner,
    ConfirmDelete,
  },
  data() {
    return {
      modalShow: false,
      header: [
        this.$t("admin.active"),
        this.$t("add_career.start_date"),
        this.$t("add_career.end_date"),
        this.$t("admin_sidebar.career_category"),
        this.$t("add_career.location"),
        this.$t("admin.description"),
        this.$t("admin.keywords"),
        this.$t("admin.edit"),
        this.$t("admin.delete"),
      ],
      allCareer: [],
      allCategory: [],
      checkedItems: [],
      edit: false,
      active_id: "",
      update: {
        startDate: "",
        endDate: "",
        show: false,
        keywords: [],
        category: null,
        location: null,
        descriptionKA: "",
        descriptionEN: "",
      },
      components: [{ comp: "comp" }],
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getCareer();
    this.getCategory();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    onDrop(dropResult) {
      this.allCareer = applyDrag(this.allCareer, dropResult);
      axios.post(`${env.host}/edit/full/career`, this.allCareer).then((res) => {
        console.log(res);
      });
    },
    getCategory() {
      axios.get(`${env.host}/get/all/career/categories/admin`).then((res) => {
        console.log(res);
        this.allCategory = res.data.item;
      });
    },
    getCareer() {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/all/career/${null}/${null}/${null}/${null}`)
        .then((res) => {
          res.data.item.map((career, index) => {
            this.$nextTick(() => {
              this.$refs["descriptionKA" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", career.descriptionKA);
              });
            });
            this.$nextTick(() => {
              this.$refs["descriptionEN" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", career.descriptionEN);
              });
            });
          });
          this.isLoading = false;
          this.allCareer = res.data.item;
        });
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    delete_keywords(index) {
      if (index > -1) {
        this.update.keywords.splice(index, 1);
      }
    },
    get_value(e) {
      this.form.keywords.push(e.target.value);
    },
    editCareer(
      id,
      startDate,
      endDate,
      show,
      keywords,
      category,
      location,
      descriptionKA,
      descriptionEN
    ) {
      this.edit = true;
      this.active_id = id;
      this.update.startDate = startDate;
      this.update.endDate = endDate;
      this.update.show = show;
      this.update.keywords = keywords;
      this.update.category = category;
      this.update.location = location;
      this.update.descriptionKA = descriptionKA;
      this.update.descriptionEN = descriptionEN;
    },
    UpdateCareer() {
      this.isLoading = true;
      axios
        .post(`${env.host}/edit/career/${this.active_id}`, this.update)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getCareer();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
      this.edit = false;
    },
    deleteCareer(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/career/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete"),
        });
        this.isLoading = false;
        this.getCareer();
      });
    },
  },
};
</script>

<style scoped>
.testingScroll {
  width: 100% !important;
  min-height: calc(100vh - 240px);

  max-height: calc(100vh - 200px) !important;
  overflow-y: scroll;
  overflow-x: scroll;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
  width: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}

.text_editor label {
  font-family: mtavruliBOLD;
}
label {
  margin-top: 10px;
  word-wrap: break-word !important;
}

.career_management {
  padding: 5px 10px 10px 10px;
  width: 100%;
  overflow: hidden;
}
.box {
  word-break: break-all;
  /* word-wrap: break-word; */
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 5px;
  border: 1px solid #cccccc;
}
.main_div {
  width: 100%;
  background: #f2f5fa;
  display: flex;
  margin-bottom: 20px;
  border-radius: 0px;
  overflow: hidden;
  float: left;
}
.main_div2 {
  width: 100%;
  background: #f2f5fa;
  display: flex;
  margin-bottom: 20px;
  border-radius: 0px;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #7e5493;
  border-radius: 0px;
}
.show_on_front {
  font-size: 30px;
}
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.delete {
  background: #f02849;
  border-radius: 0px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}

.delete i,
.edit i {
  color: white;
}
.add_comp i {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}
input[type="checkbox"] {
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  padding: 10px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
.input,
select,
.choosed_keywords {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  height: 40px;
  padding-left: 10px;
}
</style>
